import { useTranslation } from 'next-i18next';
import { useWrappedRouter } from '../../../hooks';
import { getFeatureItems, getHotelTypeItems } from './amenity-tiles-utils';
import { DynamicGrid } from '@dx-ui/osc-dynamic-grids';

export const AmenityTiles = () => {
  const {
    router: { locale },
  } = useWrappedRouter();
  const { t } = useTranslation('default-locations-page');

  const amenitiesByHotelType = getHotelTypeItems(t, locale || 'en');
  const amenitiesByFeature = getFeatureItems(t, locale || 'en');

  return !!amenitiesByHotelType?.length || amenitiesByFeature?.length ? (
    <div className="pb-0 pt-8">
      <h2 className="text-primary heading-2xl sm:heading-4xl flex items-center pb-4 leading-snug">
        {t('attributeTiles.exploreTheWorld')}
      </h2>

      <h3 className="flex items-center text-lg font-bold leading-snug sm:text-xl">
        {t('attributeTiles.browseFeature')}
      </h3>
      <DynamicGrid
        items={amenitiesByFeature}
        id="feature"
        imageAspectRatio="4:3"
        className="!py-1 px-0"
      />

      <h3 className="flex items-center text-lg font-bold leading-snug sm:text-xl">
        {t('attributeTiles.browseHotel')}
      </h3>
      <DynamicGrid
        items={amenitiesByHotelType}
        id="hotel"
        imageAspectRatio="4:3"
        className="!py-1 px-0"
      />
    </div>
  ) : null;
};
