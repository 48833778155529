import { Link } from '@dx-ui/osc-link';
import type { ParseKeys } from 'i18next';
import { Trans, useTranslation } from 'next-i18next';
import { GraphError, type DXError, type DXExtension } from '@dx-ui/types-graphql';

type GenAIKeys = ParseKeys<'osc-genai-chat'>;

const errorMapper = (statusCode: DXExtension['code']): { url: string; i18nKey: GenAIKeys } => {
  switch (statusCode) {
    case '409':
      return { i18nKey: 'conversationLimitReachedMsg', url: '/en/hilton-honors/points-explorer/' };
    default:
      return { i18nKey: 'genericErrorMsg', url: '/en/locations/' };
  }
};

export const GenaiChatErrorMessage = ({ key, error }: { key: string; error?: DXError }) => {
  const { t } = useTranslation('osc-genai-chat');
  const statusCode =
    error instanceof GraphError ? error.graphQLErrors?.[0]?.extensions?.code : undefined;
  const errorInfo = errorMapper(statusCode);

  return (
    <Trans key={key} t={t} i18nKey={errorInfo.i18nKey}>
      <Link className="text-text break-words" url={errorInfo.url} isNewWindow />
    </Trans>
  );
};
