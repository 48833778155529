import { env } from '../../../constants';
import type { TFunction } from 'i18next';
import { prefixPublicAssetUriWithAppPrefix } from '../../../utils';
const baseURL = env.OHW_BASE_URL;
const defaultItemLinkProps = {
  isNewWindow: false,
};

export const getFeatureItems = (t: TFunction<'default-locations-page'>, locale: string) => {
  return [
    {
      id: 'indoor_pool_tile',
      imageAltTxt: t('attributeTiles.features.indoorPool.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/feature/indoor_pool.webp'),
      itemTitle: t('attributeTiles.features.indoorPool.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.features.indoorPool.LinkLabel'),

        url: `${baseURL}${locale}/locations/indoor-pool`,
      },
      modalImageAltText: t('attributeTiles.features.indoorPool.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/feature/indoor_pool.webp'
      ),
      shortDescription: t('attributeTiles.features.indoorPool.shortDescription'),
      segmentIds: [],
    },
    {
      id: 'beach_tile',
      imageAltTxt: t('attributeTiles.features.beach.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/feature/beach.webp'),
      itemTitle: t('attributeTiles.features.beach.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.features.beach.LinkLabel'),

        url: `${baseURL}${locale}/locations/beach`,
      },
      modalImageAltText: t('attributeTiles.features.beach.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/feature/beach.webp'),
      shortDescription: t('attributeTiles.features.beach.shortDescription'),
      segmentIds: [],
    },
    {
      id: 'outdoor_pool_tile',
      imageAltTxt: t('attributeTiles.features.outdoorPool.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/feature/outdoor_pool.webp'
      ),
      itemTitle: t('attributeTiles.features.outdoorPool.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.features.outdoorPool.LinkLabel'),

        url: `${baseURL}${locale}/locations/outdoor-pool`,
      },
      modalImageAltText: t('attributeTiles.features.outdoorPool.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/feature/outdoor_pool.webp'
      ),
      shortDescription: t('attributeTiles.features.outdoorPool.shortDescription'),
      segmentIds: [],
    },
    {
      id: 'spa_tile',
      imageAltTxt: t('attributeTiles.features.spa.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/feature/spa.webp'),
      itemTitle: t('attributeTiles.features.spa.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.features.spa.LinkLabel'),

        url: `${baseURL}${locale}/locations/spa`,
      },
      modalImageAltText: t('attributeTiles.features.spa.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/feature/spa.webp'),
      shortDescription: t('attributeTiles.features.spa.shortDescription'),
      segmentIds: [],
    },
  ];
};

export const getHotelTypeItems = (t: TFunction<'default-locations-page'>, locale: string) => {
  return [
    {
      id: 'pet_friendly_tile',
      imageAltTxt: t('attributeTiles.hotelType.petFriendly.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/hotel-type/pet_friendly.webp'
      ),
      itemTitle: t('attributeTiles.hotelType.petFriendly.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.hotelType.petFriendly.LinkLabel'),

        url: `${baseURL}${locale}/locations/pet-friendly`,
      },
      modalImageAltText: t('attributeTiles.hotelType.petFriendly.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/hotel-type/pet_friendly.webp'
      ),
      shortDescription: t('attributeTiles.hotelType.petFriendly.shortDescription'),
      segmentIds: [],
    },
    {
      id: 'resort_tile',
      imageAltTxt: t('attributeTiles.hotelType.resort.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/hotel-type/resort.webp'),
      itemTitle: t('attributeTiles.hotelType.resort.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.hotelType.resort.LinkLabel'),

        url: `${baseURL}${locale}/locations/resorts`,
      },
      modalImageAltText: t('attributeTiles.hotelType.resort.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/hotel-type/resort.webp'
      ),
      shortDescription: t('attributeTiles.hotelType.resort.shortDescription'),
      segmentIds: [],
    },
    {
      id: 'all_inclusive_tile',
      imageAltTxt: t('attributeTiles.hotelType.allInclusive.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/hotel-type/all_inclusive.webp'
      ),
      itemTitle: t('attributeTiles.hotelType.allInclusive.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.hotelType.allInclusive.LinkLabel'),

        url: `${baseURL}${locale}/locations/all-inclusive`,
      },
      modalImageAltText: t('attributeTiles.hotelType.allInclusive.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/hotel-type/all_inclusive.webp'
      ),
      shortDescription: t('attributeTiles.hotelType.allInclusive.shortDescription'),
      segmentIds: [],
    },
    {
      id: 'luxury_tile',
      imageAltTxt: t('attributeTiles.hotelType.luxury.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/hotel-type/luxury.webp'),
      itemTitle: t('attributeTiles.hotelType.luxury.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.hotelType.luxury.LinkLabel'),

        url: `${baseURL}${locale}/locations/luxury`,
      },
      modalImageAltText: t('attributeTiles.hotelType.luxury.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/hotel-type/luxury.webp'
      ),
      shortDescription: t('attributeTiles.hotelType.luxury.shortDescription'),
      segmentIds: [],
    },
  ];
};
